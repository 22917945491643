import { CircularProgress, Icon } from "@mui/material";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";

// @mui material components
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import classImage from "assets/images/class.jpg";
import clockSettingsImage from "assets/images/clock_settings.jpg";
import paymentClock from "assets/images/coins.jpg";
import { useSelector } from "react-redux";
import CostPerClass from "./costPerClass";
import PaymentTypes from "./paymentTypes";

import csvIcon from "assets/icons/csvIcon.svg";
import MDButton from "components/MDButton";
import studentAPIService from "services/studentAPI-service";
import { saveAs } from "file-saver";
import MDSnackbar from "components/MDSnackbar";

function AdminSettings() {
  const userSelector = useSelector((state) => state.auth);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState("paymentTypes");
  const [downloadProcessing, setDownloadProcessing] = useState(false);

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const downloadDBBackup = async () => {
    setDownloadProcessing(true);
    try {
      const dbBackUp = await studentAPIService.downloadDBBackUp();

      const blob = new Blob([JSON.stringify(dbBackUp.data, null, 2)], {
        type: "application/json",
      });

      // Now open save as to save this csv file
      saveAs(blob, "dbBackUp.json");
      setDownloadProcessing(false);

      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Database BackUp has been downloaded.");
      openSnackBar();
    } catch (error) {
      setSnackBarColor("error");
      setSnackBarIcon("warning");
      setSnackBarTitle("Error");
      setSnackBarMessage("Failed to download the Database BackUp.");
      openSnackBar();
      setDownloadProcessing(false);
    }
  };

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="10.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({
              functions: { rgba, linearGradient },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.2),
                rgba(gradients.info.state, 0.2)
              )}, url(${
                tabValue === "schedules"
                  ? clockSettingsImage
                  : tabValue === "costPerClass"
                  ? classImage
                  : tabValue === "paymentTypes"
                  ? paymentClock
                  : ""
              })`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          {/* Download Database BackUp */}
          {/* Show download button only when admin or superadmin */}

          <MDBox display="flex" justifyContent="right">
            <MDButton
              color="info"
              disabled={downloadProcessing}
              onClick={downloadDBBackup}
            >
              <img
                src={csvIcon}
                name="csvIcons"
                height={20}
                width={25}
                style={{ marginRight: "5px" }}
              />
              Database Backup
              {downloadProcessing && (
                <CircularProgress
                  size={14}
                  sx={{ marginLeft: 1 }}
                  color="warning"
                />
              )}
            </MDButton>
          </MDBox>

          {Array.of("superadmin", "admin").includes(userSelector.userType) && (
            <Grid container mt={2} spacing={3} alignItems="center">
              <Grid item xs={12} md={8} lg={6} sx={{ ml: "auto" }}>
                <AppBar position="static">
                  <Tabs
                    orientation={tabsOrientation}
                    value={tabValue}
                    onChange={handleSetTabValue}
                  >
                    <Tab
                      value="costPerClass"
                      label="Class Cost"
                      icon={
                        <Icon fontSize="small" sx={{ mt: -0.25 }}>
                          paid
                        </Icon>
                      }
                    />

                    <Tab
                      value="paymentTypes"
                      label="Payment Methods"
                      icon={
                        <Icon fontSize="small" sx={{ mt: -0.25 }}>
                          local_atm
                        </Icon>
                      }
                    />
                  </Tabs>
                </AppBar>
              </Grid>
            </Grid>
          )}
          <MDBox mt={5} width="100%">
            {tabValue === "costPerClass" ? <CostPerClass /> : <PaymentTypes />}
          </MDBox>
        </Card>
      </MDBox>
      {renderSnackBar}
    </DashboardLayout>
  );
}

export default AdminSettings;
